import MicroModal from 'micromodal';
import Swiper, { Scrollbar, Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

const swiper = new Swiper('.swiper--product', {
  // Optional parameters
  loop: false,
  modules: [Scrollbar],
  slidesPerView: 1.2,
  spaceBetween: 18,
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar'
  },
  breakpoints: {
    640: {
      slidesPerView: 2.2,
      spaceBetween: 24
    },
    1024: {
      slidesPerView: 2.2,
      spaceBetween: 24
    },
    1560: {
      slidesPerView: 2.8,
      spaceBetween: 36
    }
  }
});
const images = new Swiper('.swiper--images', {
  // Optional parameters
  loop: true,
  modules: [Scrollbar, Autoplay],
  slidesPerView: 0.8,
  spaceBetween: 24,
  speed: 2000,
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar'
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true
  }
});
const marqueeSpeed = 6000;
const autoSlide = new Swiper('.swiper--auto-slide', {
  modules: [Autoplay, Scrollbar, EffectFade],
  centeredSlides: true,
  speed: marqueeSpeed,
  slidesPerView: 1,
  loop: true,
  disableOnInteraction: true,
  loopedSlides: 4,
  allowTouchMove: false,
  autoplay: {
    delay: 5,
    disableOnInteraction: false,
    pauseOnMouseEnter: true
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  scrollbar: {
    el: '.swiper-scrollbar'
  }
});
const carouselSlide = new Swiper('.swiper--carousel', {
  modules: [Autoplay, Scrollbar],
  centeredSlides: true,
  slidesPerView: 1,
  loop: true,
  loopedSlides: 1,
  allowTouchMove: true,
  speed: 1000,
  autoplay: {
    delay: 6000,
    pauseOnInteraction: true
  },
  scrollbar: {
    el: '.swiper-scrollbar'
  }
});
const pureAutoSlide = new Swiper('.swiper--pure-slider', {
  modules: [Autoplay],
  centeredSlides: true,
  speed: marqueeSpeed,
  loop: true,
  disableOnInteraction: false,
  loopedSlides: 4,
  allowTouchMove: false,
  autoplay: {
    delay: 1,
    reverseDirection: true,
    disableOnInteraction: false,
    pauseOnMouseEnter: true
  },
  slidesPerView: 1.2,
  spaceBetween: 16,
  breakpoints: {
    640: {
      slidesPerView: 2.2,
      spaceBetween: 16
    },
    1024: {
      slidesPerView: 2.2,
      spaceBetween: 32
    },
    1560: {
      slidesPerView: 3.2,
      spaceBetween: 96
    }
  }
});
MicroModal.init();

function toggleMenu() {
  var navBtn = document.querySelector('#navBtn > .nav-icon');
  var navBtnIcon = document.querySelector('#navBtn > .nav-icon');
  var navigation = document.querySelector('#navigation');
  var announcement = document.querySelector('#announcement');

  if (announcement) {
    announcement.classList.toggle('announcement--open');
  }

  navBtn.classList.toggle('navBtn--open');
  navBtnIcon.classList.toggle('nav-icon--open');
  navigation.classList.toggle('navigation--open');
  document.body.classList.toggle('no-scroll');
}
function closeMenu() {
  var navBtn = document.querySelector('#navBtn > .nav-icon');
  var navBtnIcon = document.querySelector('#navBtn > .nav-icon');
  var navigation = document.querySelector('#navigation');
  var announcement = document.querySelector('#announcement');

  if (announcement) {
    announcement.classList.remove('announcement--open');
  }
  navBtn.classList.remove('navBtn--open');
  navBtnIcon.classList.remove('nav-icon--open');
  navigation.classList.remove('navigation--open');
  document.body.classList.remove('no-scroll');
}

document.querySelector('#navBtn').addEventListener('click', toggleMenu);
document.querySelector('#kontakt-link').addEventListener('click', closeMenu);

window.onload = function get_body() {
  const forms = document.querySelectorAll('form[id^="wirnshofer_"]');
  const processForm = (form) => (event) => {
    event.preventDefault();
    fetch('/form.php', {
      method: 'POST',
      body: new FormData(event.target)
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then(function (data) {
        if (data.message) {
          const responseElem = document.getElementById(form.id + '--response');
          responseElem.innerHTML = data.message;
          responseElem.style = 'display: block;';
          if (data.code === 200) {
            form.remove();
          }
        }
      })
      .catch(function (error) {
        console.warn(error);
      });
  };
  for (const form of forms) {
    form.addEventListener('submit', processForm(form));
  }
};
